import {
  CopySimple,
  FireSimple,
  IconProps,
  ImageSquare,
  MagicWand,
  Pencil,
  PushPin,
  Skull,
  Trash,
  Warning,
} from "@phosphor-icons/react";
import React, { useMemo } from "react";
import { getShopifyImage } from "src/lite/helpers/getShopifyImage";
import type {
  LiteBoostBuryRule,
  LiteCollectionListDto,
  LiteCollectionProductDto,
  ShopifyImage,
} from "../../api/types";
import { dynamicStatusMeta } from "../../lite/views/BoostBury/utils";
import { Badge } from "../Badges/Badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/Tooltip";
import { Image } from "./Image";
import { DragAndDropZones } from "../../lite/views/Collection/DragAndDrop/DragAndDropZones";
import { DraggableWrapper } from "../../lite/views/Collection/DragAndDrop/DraggableWrapper";
import { css, Global, useTheme } from "@emotion/react";
import { useDragAndDropContext } from "../../lite/views/Collection/DragAndDrop/DragAndDropProvider";
import { productBadgeTypeToData } from "./ProductBadgeTypeToData";
import { StatusBadge } from "../Badges/StatusBadge";
import { useMultiStore } from "../../lite/helpers/useMultiStore";
import { useIsDarkMode } from "../../helpers/hooks/app/useIsDarkMode";
import { Button } from "../Button/Button";
import { UnPublishedBadge } from "../../lite/views/Collection/CollectionTemplate/UnPublishedBadge";
import {
  SwitchToggleItem,
  SwitchToggleRoot,
} from "../SwitchToggle/SwitchToggle";
import { Checkbox } from "../Checkbox/Checkbox";
import { DataSubSection } from "./DataSubSection";
import {
  getOriginalProductIdForDuplicateProduct,
  getProductIdForDuplicateProduct,
} from "../../lite/views/Collection/ProductHighlight/productDuplicateHelpers";

export const IMG_ASPECT_RATIO = 250 / 350;

export const openOptionsClassName = "product-card-open-options-button";

interface ProductCardProps extends React.HTMLAttributes<HTMLDivElement> {
  pinned: boolean;
  globalProductData?: {
    orders: number | null;
  };
  collectionProductData?: {
    clicks: number;
    percentOfMaxClicks: number | null;
  };
  collectingClicks?: boolean;
  showData?: boolean;
  dataTimeFrame?: number;
  product: LiteCollectionProductDto;
  selected?: boolean;
  collections: LiteCollectionListDto[];
  openImageSettings: (product: LiteCollectionProductDto) => void;
  pinSelectionOrProduct: (mainProductId?: string) => void;
  unpinSelectionOrProduct: (mainProductId?: string) => void;
  isProductDuplicate: boolean;
  hasProductDuplicate: boolean;
  onProductDuplicateAddClick: (
    productId: string,
    defaultImage: ShopifyImage,
    hoverImage: ShopifyImage | null
  ) => void;
  onProductDuplicateDeleteClick: (productId: string) => void;
  showProductCardUnPublishedBadge: boolean;
  originalPinned?: boolean;
  setOpenCollectionTemplateSettings: () => void;
  isDepictTemplateSelectedAndNotSaved: boolean;
  growToImageAspectRatio?: boolean;
  usingManualSort: boolean;
  isSearchMatch?: boolean;
}

function BoostBuryBadge({
  boostBuryRule,
  collections,
  disabledByPin,
}: {
  boostBuryRule: LiteBoostBuryRule["rule"] | null;
  collections: LiteCollectionListDto[];
  disabledByPin?: boolean;
}) {
  const theme = useTheme();

  const collectionTitle =
    boostBuryRule?.type === "collection"
      ? collections?.find(
          (c) => c.collection_id === boostBuryRule.collection_id
        )?.title
      : null;

  if (!boostBuryRule) return null;

  const backgroundColor = () => {
    if (disabledByPin) {
      return theme.colors.bg["weak-50"];
    }
    if (boostBuryRule.strength > 0) {
      return theme.colors.primary.base;
    } else {
      return theme.colors.bg["strong-950"];
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background: ${backgroundColor()};
            border-radius: 50%;
            color: ${disabledByPin
              ? theme.colors.icon["disabled-300"]
              : theme.colors.text["white-0"]};
          `}
        >
          {boostBuryRule.strength > 0 ? (
            <FireSimple size={12} weight={"fill"} />
          ) : (
            <Skull size={12} weight={"fill"} />
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <div
          css={css`
            text-decoration: ${disabledByPin ? "line-through" : "none"};
          `}
        >
          {boostBuryRule.type === "badge" &&
            `${
              boostBuryRule.strength > 0 ? "Boosted" : "Buried"
            } by status: ${dynamicStatusMeta()[boostBuryRule.badge].title}`}
          {boostBuryRule.type === "tag" &&
            `${
              boostBuryRule.strength > 0 ? "Boosted" : "Buried"
            } by tag: ${boostBuryRule.tags.at(0)}`}
          {boostBuryRule.type === "collection" &&
            `${
              boostBuryRule.strength > 0 ? "Boosted" : "Buried"
            } by collection: ${collectionTitle}`}
        </div>
        {disabledByPin && <div> Disabled by Pin</div>}
      </TooltipContent>
    </Tooltip>
  );
}

const productBadgeTooltip = (
  badge: LiteCollectionProductDto["badges"][number]
) => {
  switch (badge.type) {
    case "bestseller":
      return `${badge.value} sold in the last ${badge.n_days} days`;
    case "on_sale":
      return "On Sale";
    case "new_in":
      return `Published at ${new Date(badge.published_at).toLocaleDateString()}`;
    case "slow_mover":
      return `Sellthrough-rate ${badge.value.toFixed(1)}% in the last ${badge.n_days} days`;
    case "out_of_stock":
      return "Out of Stock";
    case "few_variants_available":
      return `${badge.variant_options_available.length} variants in stock out of ${badge.n_variants_total}`;
    case "trending":
      return `${badge.value} sold in the last ${badge.n_days} days`;
    default:
      return "";
  }
};

export function ProductBadge({
  badge,
  size = "medium",
}: {
  size?: "small" | "medium";
  badge: LiteCollectionProductDto["badges"][number];
}) {
  const { title, color } = productBadgeTypeToData[badge.type];
  const isDark = useIsDarkMode();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Badge
          _style={isDark ? "filled" : "light"}
          text={title}
          color={color}
          size={size}
        />
      </TooltipTrigger>
      <TooltipContent>{productBadgeTooltip(badge)}</TooltipContent>
    </Tooltip>
  );
}

function DraftProductBadge() {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Badge
          _style={"filled"}
          text={"DRAFT"}
          color={"gray"}
          size={"medium"}
          icon={<Pencil />}
        />
      </TooltipTrigger>
      <TooltipContent>
        Product is in draft mode, hence it will not show up on the storefront.
        Activate in Shopify.
      </TooltipContent>
    </Tooltip>
  );
}

export const ProductCardContainer: React.FC<{
  children: React.ReactNode;
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  duplicate?: boolean;
  productId: string;
  hasDuplicate?: boolean;
  isSearchMatch?: boolean;
}> = ({
  children,
  selected,
  onClick,
  duplicate,
  hasDuplicate,
  productId,
  isSearchMatch,
  ...props
}) => {
  const theme = useTheme();
  const selectedShadow = `0 0 0 1px ${theme.colors.primary.base}`;
  const normalBoxShadows = [theme.shadows.regular.xs];
  const hoveredBoxShadows = [theme.shadows.large];
  const isDark = useIsDarkMode();

  if (selected) {
    normalBoxShadows.unshift(selectedShadow);
    // We need to add the selected shadow in the same index in the hovered ones, otherwise the selected shadow will get morphed in the transition into the background shadow
    hoveredBoxShadows.splice(0 - 1, 0, selectedShadow);
  }

  const hoverStyling = `box-shadow: ${hoveredBoxShadows.join(", ")};
            ${
              selected === false
                ? `border: 1px solid
              ${isDark ? theme.colors.primary.base : theme.colors.primary.dark};`
                : ``
            }`;

  return (
    <>
      <div
        data-product-id={productId}
        className={"card-container"}
        onClick={onClick}
        css={[
          css`
            flex-grow: 1;
            user-select: none;
            display: flex;
            flex-direction: column;
            border: 1px solid black;
            padding: 3px; // 3 + 1 = 4, border width
            border-radius: 12px;
            min-width: 0;
            transition:
              box-shadow 200ms ease-in-out,
              border 200ms ease-in-out;

            background: ${theme.colors.bg["white-0"]};
            box-shadow: ${normalBoxShadows.join(", ")};

            border: 1px solid
              ${selected
                ? theme.colors.primary.base
                : theme.colors.stroke["soft-200"]};

            @media (hover: hover) {
              // Only show hover shadow and outline if hover is supported since the pattern doesn't work on mobile (you just select on press anyway)
              &:hover {
                ${hoverStyling}
              }
            }
            // For the menu buttons we want to use the mobile hover emulation as there's otherwise no other way to access them
            &:hover {
              .hover-menu {
                display: flex;
              }
              .non-hover-menu {
                display: none;
              }
            }
          `,
          isSearchMatch === false &&
            css`
              opacity: 0.3;
            `,
        ]}
        {...props}
      >
        {children}
      </div>
      {/*Sync hover effects between duplicates and their original products*/}
      {duplicate && (
        <Global
          styles={css`
            @media (hover: hover) {
              .dnd-grid:has(
                  .card-container[data-product-id="${getOriginalProductIdForDuplicateProduct(
                      productId
                    )}"]:hover
                )
                .card-container[data-product-id="${productId}"] {
                ${hoverStyling}
              }
            }
          `}
        />
      )}
      {hasDuplicate && (
        <Global
          styles={css`
            @media (hover: hover) {
              .dnd-grid:has(
                  .card-container[data-product-id="${getProductIdForDuplicateProduct(
                      productId
                    )}"]:hover
                )
                .card-container[data-product-id="${productId}"] {
                ${hoverStyling}
              }
            }
          `}
        />
      )}
    </>
  );
};

const RightTopCornerMenu = ({
  pinned,
  usingManualSort,
  product,
}: {
  pinned: boolean;
  usingManualSort: boolean;
  product: LiteCollectionProductDto;
}) => {
  const theme = useTheme();
  return (
    <div
      className={"non-hover-menu"}
      css={css`
        position: absolute;
        top: 4px;
        right: 4px;
      `}
    >
      {!pinned && !product.duplicate && (
        <Button
          className={"product-card-pin-indicator"}
          variant={"neutral"}
          _style={"stroke"}
          size={"xx-small"}
          iconOnly
        >
          <MagicWand
            size={20}
            weight={"fill"}
            color={theme.colors.primary.base}
          />
        </Button>
      )}
      {pinned && !product.duplicate && !usingManualSort && (
        <Button
          className={"product-card-pin-indicator"}
          variant={"neutral"}
          _style={"stroke"}
          size={"xx-small"}
          iconOnly
        >
          <PushPin
            size={20}
            weight={"fill"}
            color={theme.colors.neutral.static.black}
          />
        </Button>
      )}

      {product.duplicate && (
        <Button
          className={"product-card-duplicate-indicator"}
          variant={"neutral"}
          _style={"stroke"}
          size={"xx-small"}
          iconOnly
        >
          <CopySimple
            size={20}
            weight={"fill"}
            color={theme.colors.icon["soft-400"]}
          />
        </Button>
      )}
    </div>
  );
};

const RightTopCornerHoverMenu = ({
  pinned,
  usingManualSort,
  pinSelectionOrProduct,
  setSelectedItems,
  unpinSelectionOrProduct,
  onProductDuplicateDeleteClick,
  onProductDuplicateAddClick,
  product,
  openImageSettings,
  productCardWidth,
}: {
  pinned: boolean;
  usingManualSort: boolean;
  pinSelectionOrProduct: (mainProductId?: string) => void;
  unpinSelectionOrProduct: (mainProductId?: string) => void;
  setSelectedItems: (items: Set<string>) => void;
  onProductDuplicateDeleteClick: (productId: string) => void;
  onProductDuplicateAddClick: (
    productId: string,
    defaultImage: ShopifyImage,
    hoverImage: ShopifyImage | null
  ) => void;
  product: LiteCollectionProductDto;
  openImageSettings: (product: LiteCollectionProductDto) => void;
  productCardWidth: number | undefined;
}) => {
  const theme = useTheme();

  const MagicWandFillIcon = React.forwardRef<SVGSVGElement, IconProps>(
    (props, ref) => {
      return <MagicWand ref={ref} weight="fill" {...props} />;
    }
  );

  const PushPinFillIcon = React.forwardRef<SVGSVGElement, IconProps>(
    (props, ref) => {
      return <PushPin ref={ref} weight="fill" {...props} />;
    }
  );

  const productWidth = productCardWidth ?? 0;

  return (
    <div
      className={"hover-menu"}
      css={css`
        position: absolute;
        top: 4px;
        right: 4px;
        display: none;
        gap: 8px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      {!product.duplicate && !pinned && (
        <SwitchToggleRoot
          value={"auto"}
          onValueChange={() => {
            pinSelectionOrProduct(product.main_product_id);
          }}
        >
          <SwitchToggleItem
            text={productWidth >= 190 ? "Manual" : ""}
            value={"manual"}
            LeftIcon={PushPin}
          />
          <SwitchToggleItem
            text={productWidth >= 190 ? "Auto" : ""}
            value={"auto"}
            LeftIcon={MagicWandFillIcon}
            color={theme.colors.primary.base}
          />
        </SwitchToggleRoot>
      )}
      {!product.duplicate && pinned && !usingManualSort && (
        <SwitchToggleRoot
          value={"manual"}
          onValueChange={() => {
            unpinSelectionOrProduct(product.main_product_id);
          }}
        >
          <SwitchToggleItem
            text={productWidth >= 190 ? "Manual" : ""}
            value={"manual"}
            LeftIcon={PushPinFillIcon}
            color={theme.colors.neutral.static.black}
          />
          <SwitchToggleItem
            text={productWidth >= 190 ? "Auto" : ""}
            value={"auto"}
            LeftIcon={MagicWand}
          />
        </SwitchToggleRoot>
      )}
      {!product.duplicate && pinned && usingManualSort && (
        <Button
          className={"product-card-pin-indicator"}
          variant={"neutral"}
          _style={"stroke"}
          size={"xx-small"}
          disabled={true}
          iconOnly
        >
          <PushPin size={20} weight={"fill"} />
        </Button>
      )}
      {product.duplicate && (
        <Button
          className={"product-card-unduplicate-button"}
          variant={"neutral"}
          _style={"stroke"}
          size={"xx-small"}
          onClick={() => {
            onProductDuplicateDeleteClick(product.main_product_id);
            setSelectedItems(new Set());
          }}
          customCss={css`
            width: fit-content;
          `}
        >
          <Trash size={20} />
          {productWidth >= 190 && "Delete"}
        </Button>
      )}
      <Button
        className={"product-card-edit-image-button"}
        variant={"neutral"}
        _style={"stroke"}
        size={"xx-small"}
        onClick={() => {
          openImageSettings(product);
          setSelectedItems(new Set());
        }}
        customCss={css`
          width: fit-content;
        `}
      >
        <ImageSquare size={20} />
        {productWidth >= 190 && "Edit"}
      </Button>
      {!product.duplicate && !product.hasDuplicate && (
        <Button
          className={"product-card-duplicate-button"}
          variant={"neutral"}
          _style={"stroke"}
          size={"xx-small"}
          onClick={() => {
            if (product.images) {
              onProductDuplicateAddClick(
                product.main_product_id,
                product.images.length > 1
                  ? product.images[1]
                  : product.images[0],
                product.images.length > 1 ? product.images[0] : null
              );
            }
            setSelectedItems(new Set());
          }}
          customCss={css`
            width: fit-content;
          `}
        >
          <CopySimple size={20} />
          {productWidth >= 190 && "Duplicate"}
        </Button>
      )}
      {!product.duplicate && product.hasDuplicate && (
        <Button
          className={"product-card-delete-duplicate-button"}
          variant={"neutral"}
          _style={"stroke"}
          size={"xx-small"}
          onClick={() => {
            onProductDuplicateDeleteClick(product.main_product_id);
            setSelectedItems(new Set());
          }}
          customCss={css`
            width: fit-content;
          `}
        >
          <Trash size={20} />
          {productWidth >= 190 && "Delete duplicate"}
        </Button>
      )}
    </div>
  );
};

const LeftTopCornerHoverMenu = ({
  selected,
}: {
  selected: boolean | undefined;
}) => {
  return (
    <div
      className={"hover-menu"}
      css={css`
        position: absolute;
        top: 4px;
        left: 4px;
        display: none;
        gap: 8px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <Checkbox onChange={() => {}} checked={selected} />
    </div>
  );
};

function ProductCard({
  selected,
  pinned,
  product,
  globalProductData,
  collectionProductData,
  collectingClicks,
  showData,
  dataTimeFrame,
  collections,
  openImageSettings,
  isProductDuplicate,
  isSearchMatch,
  pinSelectionOrProduct,
  unpinSelectionOrProduct,
  originalPinned,
  onProductDuplicateAddClick,
  onProductDuplicateDeleteClick,
  showProductCardUnPublishedBadge,
  setOpenCollectionTemplateSettings,
  isDepictTemplateSelectedAndNotSaved,
  growToImageAspectRatio,
  usingManualSort,
  ...props
}: ProductCardProps) {
  const theme = useTheme();
  const { byShopId: multiStores } = useMultiStore();

  const { setSelectedItems, selectedItems, productCardWidth } =
    useDragAndDropContext();
  const missingShopIds = useMemo(() => {
    if (!product.connected_shop_ids || !multiStores) return [];

    return Object.keys(multiStores).filter(
      (shopId) => !product.connected_shop_ids?.includes(shopId)
    );
  }, [product.connected_shop_ids, multiStores]);

  const isPinnedInDND = pinned || originalPinned;

  return (
    <DraggableWrapper>
      <ProductCardContainer
        selected={selected}
        duplicate={product.duplicate}
        hasDuplicate={product.hasDuplicate}
        {...props}
        productId={product.main_product_id}
        isSearchMatch={isSearchMatch}
      >
        <div
          // These classes are used for jimo tracking cant be changed.
          className="product-card image-hover"
          css={css`
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            width: 100%;
            flex: 1;
            ${growToImageAspectRatio
              ? `aspect-ratio: ${IMG_ASPECT_RATIO};`
              : ``}
          `}
        >
          <Image
            img={
              product.image_urls[0]
                ? getShopifyImage(product.image_urls[0], "large")
                : undefined
            }
            hoverImg={
              product.image_urls[1]
                ? getShopifyImage(product.image_urls[1], "large")
                : undefined
            }
            title={product.title}
          />

          {(missingShopIds?.length ?? 0) > 0 && multiStores && (
            <div
              css={css`
                display: flex;
                gap: 2px;
                flex-direction: column;
                position: absolute;
                top: 4px;
                left: 4px;
              `}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <StatusBadge
                    _style={"lighter"}
                    status={"pending"}
                    text={""}
                    icon={<Warning />}
                  />
                </TooltipTrigger>
                <TooltipContent side={"bottom"}>
                  <p
                    css={[
                      theme.typography["label-xs"],
                      css`
                        color: ${theme.colors.text["sub-600"]};
                        margin: 0;
                      `,
                    ]}
                  >
                    Could not find a product with the same SKU in the following
                    stores:
                    {missingShopIds?.map((store) => (
                      <div key={store}>
                        {multiStores[store].shopify_base_url}
                      </div>
                    ))}
                  </p>
                </TooltipContent>
              </Tooltip>
            </div>
          )}
          <div
            css={css`
              display: flex;
              gap: 2px;
              flex-direction: column;
              position: absolute;
              bottom: 4px;
              left: 4px;
            `}
          >
            {showProductCardUnPublishedBadge && (
              <UnPublishedBadge
                onToolTipClick={setOpenCollectionTemplateSettings}
                status={
                  isDepictTemplateSelectedAndNotSaved ? "warning" : "error"
                }
              />
            )}
            {product.status === "DRAFT" && <DraftProductBadge />}
            {!usingManualSort && (
              <BoostBuryBadge
                collections={collections}
                boostBuryRule={product.applied_boost_bury_rule}
                disabledByPin={pinned || isProductDuplicate}
              />
            )}
            {product.badges.map((badge, index) => (
              <ProductBadge key={index} badge={badge} />
            ))}
          </div>

          <LeftTopCornerHoverMenu selected={selected} />

          {selectedItems.size > 0 && (
            <div
              className={"non-hover-menu"}
              css={css`
                position: absolute;
                top: 4px;
                left: 4px;
              `}
            >
              <Checkbox onChange={() => {}} checked={selected} />
            </div>
          )}

          <RightTopCornerMenu
            pinned={pinned}
            usingManualSort={usingManualSort}
            product={product}
          />
          <RightTopCornerHoverMenu
            product={product}
            pinned={pinned}
            usingManualSort={usingManualSort}
            pinSelectionOrProduct={pinSelectionOrProduct}
            unpinSelectionOrProduct={unpinSelectionOrProduct}
            setSelectedItems={setSelectedItems}
            onProductDuplicateDeleteClick={onProductDuplicateDeleteClick}
            onProductDuplicateAddClick={onProductDuplicateAddClick}
            openImageSettings={openImageSettings}
            productCardWidth={productCardWidth}
          />
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            gap: 8px;
            padding: 8px 4px 4px 4px;
          `}
        >
          <h5
            css={[
              theme.typography["label-s"],
              css`
                color: ${theme.colors.text["strong-950"]};
                margin: 0;
                white-space: nowrap;
                min-width: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
              `,
            ]}
          >
            {product.title}
          </h5>
          {showData && (
            <DataSubSection
              clicksInfo={
                !collectingClicks
                  ? {
                      clicks: collectionProductData?.clicks ?? 0,
                      percentOfMaxClicks:
                        collectionProductData?.percentOfMaxClicks ?? 0,
                    }
                  : undefined
              }
              orders={
                (productCardWidth ?? 0) >= 110
                  ? globalProductData?.orders ?? 0
                  : undefined
              }
              quantity={!product.duplicate ? product.quantity : undefined}
              timeFrame={dataTimeFrame ?? 30}
            />
          )}
        </div>
      </ProductCardContainer>
      <DragAndDropZones
        type="product"
        id={product.main_product_id}
        isUnpinnedProduct={!isPinnedInDND}
        forbidLeftZone={product.duplicate}
        forbidRightZone={product.hasDuplicate}
      />
    </DraggableWrapper>
  );
}

export function PlaceholderProductCard({
  height,
  aspectRatio,
}:
  | { height: number; aspectRatio?: never }
  | { aspectRatio: number; height?: never }) {
  const theme = useTheme();
  return (
    <div
      css={css`
        border-radius: 12px;
        background: ${theme.colors.bg["white-0"]};
        box-shadow: 0 1px 2px 0 rgba(10, 13, 20, 0.03);
        min-width: 0;
        background: ${theme.colors.bg["weak-50"]};
        ${height
          ? `width: 100%;
        height: ${height}px;`
          : `aspect-ratio: ${aspectRatio};`}

        /* animate skeleton */
        animation: pulse 1.5s infinite;
        @keyframes pulse {
          0% {
            background: ${theme.colors.bg["weak-50"]};
          }
          50% {
            background: ${theme.colors.bg["sub-200"]};
          }
          100% {
            background: ${theme.colors.bg["weak-50"]};
          }
        }
      `}
    />
  );
}

// Product card rendering is expensive because of all the complicated component hierarchy inside it, combined with Emotion CSS.
// Because of how the virtual DnD grid has to work, we grid container re-renders on scroll, which in turn causes all mounted product cards to re-render.
// Memoizing the component has a measurable performance improvement in production builds in this case.
export default React.memo(ProductCard);
