import { QueryKey } from "@tanstack/react-query";
import { LiteBoostBuryRule, LiteCollectionSortOrder } from "../api/types";
import {
  GetCollectionMetafieldQueryVariables,
  GetCollectionsQueryVariables,
  GetMetaobjectsQueryVariables,
  GetPagesQueryVariables,
  GetProductsQueryVariables,
} from "../generated/graphql";

// Query IDs are used to identify queries in the cache (when using react-query)
export enum QueryId {
  GetStoreIsPasswordProtected = "GetStoreIsPasswordProtected",
  GetPreviewCollection = "GetPreviewCollection",
  GetUser = "GetUser",
  GetAvailableProductTags = "GetAvailableProductTags",
  GetChangelogNews = "GetChangelogNews",
  GetCollectionsOutOfStockStatus = "GetCollectionsOutOfStockStatus",
  GetDynamicStatusConfig = "GetDynamicStatusConfig",
  GetDashboardCollectionData = "GetDashboardCollectionData",
  GetEnrichedCollectionData = "GetEnrichedCollectionData",
  GetDashboardProductData = "GetDashboardProductData",
  GetDashboardProducts = "GetDashboardProducts",
  GetDashboardStatsData = "GetDashboardStatsData",
  GetCollections = "GetCollections",
  GetCollection = "GetCollection",
  GetCollectionProducts = "GetCollectionProducts",
  GetPaginatedCollectionProducts = "GetPaginatedCollectionProducts",
  GetBoostBuryRules = "GetBoostBuryRules",
  GetFeatureActivationStatus = "GetFeatureActivationStatus",
  GetShopifyManualOrdering = "GetShopifyManualOrdering",
  GetBillingStatus = "GetBillingStatus",
  GetShopifyMetaObjects = "GetShopifyMetaObjects",
  GetConfigValidity = "GetConfigValidity",
  GetShopifyThemes = "GetShopifyThemes",
  GetMultiStoreStatus = "GetMultiStoreStatus",
  ShopifyGetCollections = "ShopifyGetCollections",
  ShopifyGetProducts = "ShopifyGetProducts",
  ShopifyGetCollectionMetafield = "ShopifyGetCollectionMetafield",
  ShopifyGetMetaobjects = "ShopifyGetMetaobjects",
  ShopifyGetImageConfigurationMetafieldsQuery = "ShopifyGetImageConfigurationMetafieldsQuery",
  ShopifyGetProductDuplicatesMetafieldsQuery = "ShopifyGetProductDuplicatesMetafieldsQuery",
  ShopifyGetColumnSettingsMetafieldsQuery = "ShopifyGetColumnSettingsMetafieldsQuery",
  ShopifyGetGridSettingsMetafieldsQuery = "ShopifyGetGridSettingsMetafieldsQuery",
  GetCollectionProductClicks = "GetCollectionProductClicks",
  GetMultiStoreCollectionsByShop = "GetMultiStoreCollectionsByShop",
  GetMultiStoreCollectionMappings = "GetMultiStoreCollectionMappings",
  GetShopifyPublicCollectionImage = "GetShopifyPublicCollectionImage",
  ShopifyGetSubStoreStatus = "ShopifyGetSubStoreStatus",
  ShopifyGetPages = "ShopifyGetPages",
  ShopifyGetImageFiles = "ShopifyGetImageFiles",
  GetShopifyMainTheme = "GetShopifyMainTheme",
  GetActiveCollectionTemplate = "GetActiveCollectionTemplate",
  GetCollectionTemplates = "GetCollectionTemplates",
  GetContentBlockClicks = "GetContentBlockClicks",
}

const queryKeyBuilders = {
  ShopifyGetGridSettingsMetafieldsQuery: ({
    merchantId,
  }: {
    merchantId: string;
  }) => [QueryId.ShopifyGetGridSettingsMetafieldsQuery, merchantId],
  GetStoreIsPasswordProtected: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetStoreIsPasswordProtected,
    merchantId,
  ],
  GetPreviewCollection: ({
    collectionId,
    templateSuffix,
    merchantId,
    password,
  }: {
    collectionId: string;
    templateSuffix: string;
    merchantId: string;
    password?: string;
  }) => [
    QueryId.GetPreviewCollection,
    collectionId,
    templateSuffix,
    merchantId,
    password,
  ],
  GetUser: () => [QueryId.GetUser],
  GetAvailableProductTags: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetAvailableProductTags,
    merchantId,
  ],
  GetChangelogNews: () => [QueryId.GetChangelogNews],
  GetCollectionsOutOfStockStatus: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetCollectionsOutOfStockStatus,
    merchantId,
  ],
  GetDynamicStatusConfig: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetDynamicStatusConfig,
    merchantId,
  ],
  GetContentBlockClicks: ({
    merchantId,
    collectionId,
    fromDateString,
    toDateString,
  }: {
    merchantId: string;
    collectionId: string;
    fromDateString: string;
    toDateString: string;
  }) => [
    QueryId.GetContentBlockClicks,
    merchantId,
    collectionId,
    fromDateString,
    toDateString,
  ],
  GetDashboardCollectionData: ({
    merchantId,
    from_date,
    to_date,
    order_by,
    page_size,
  }: {
    merchantId: string;
    from_date: string;
    to_date: string;
    order_by: string;
    page_size: number;
  }) => [
    QueryId.GetDashboardCollectionData,
    merchantId,
    from_date,
    to_date,
    order_by,
    page_size,
  ],
  GetEnrichedCollectionData: ({
    merchantId,
    from_date,
    to_date,
    page_size,
    search_query,
    order_by,
  }: {
    merchantId: string;
    from_date: string;
    to_date: string;
    page_size: number;
    search_query: string | undefined;
    order_by: string;
  }) => [
    QueryId.GetEnrichedCollectionData,
    merchantId,
    from_date,
    to_date,
    page_size,
    search_query,
    order_by,
  ],
  GetDashboardProducts: ({
    merchantId,
    from_date,
    to_date,
    order_by,
    page_size,
  }: {
    merchantId: string;
    from_date: string;
    to_date: string;
    order_by: string;
    page_size: number;
  }) => [
    QueryId.GetDashboardProducts,
    merchantId,
    from_date,
    to_date,
    order_by,
    page_size,
  ],
  GetDashboardProductData: ({
    merchantId,
    fromDate,
    toDate,
    mainProductIds,
  }: {
    merchantId: string;
    fromDate: string;
    toDate: string;
    mainProductIds: string[];
  }) => [
    QueryId.GetDashboardProductData,
    merchantId,
    fromDate,
    toDate,
    mainProductIds,
  ],
  GetDashboardStatsData: ({
    merchantId,
    from_date,
    to_date,
  }: {
    merchantId: string;
    from_date: string;
    to_date: string;
  }) => [QueryId.GetDashboardProducts, merchantId, from_date, to_date],
  GetShopifyManualOrdering: ({
    collectionId,
    merchantId,
  }: {
    collectionId: string;
    merchantId: string;
  }) => [QueryId.GetShopifyManualOrdering, collectionId, merchantId],
  GetBoostBuryRules: ({
    merchantId,
    collectionId,
  }: {
    merchantId: string;
    collectionId: string | undefined;
  }) => [QueryId.GetBoostBuryRules, merchantId, collectionId],
  GetCollections: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetCollections,
    merchantId,
  ],
  GetCollection: ({
    merchantId,
    collectionId,
  }: {
    merchantId: string;
    collectionId: string;
  }) => [QueryId.GetCollection, merchantId, collectionId],
  GetCollectionProducts: ({
    merchantId,
    collectionId,
    sortOrder,
    boostBuryRules,
  }: {
    merchantId: string;
    collectionId: string;
    sortOrder?: LiteCollectionSortOrder | "SHOPIFY_SORT";
    boostBuryRules: LiteBoostBuryRule["rule"][] | null;
  }) => [
    QueryId.GetCollectionProducts,
    merchantId,
    collectionId,
    sortOrder,
    boostBuryRules
      ?.map((rule) => [
        rule.type,
        rule.type === "collection"
          ? rule.collection_id
          : rule.type === "tag"
            ? rule.tags
            : rule.type === "badge"
              ? rule.badge
              : undefined,
        rule.strength,
      ])
      .join(","),
  ],
  GetFeatureActivationStatus: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetFeatureActivationStatus,
    merchantId,
  ],
  GetBillingStatus: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetBillingStatus,
    merchantId,
  ],
  GetShopifyMetaObjects: ({
    metaObjectReferences,
    apiUrl,
    token,
  }: {
    metaObjectReferences: string[];
    apiUrl?: string;
    token?: string;
  }) => [
    QueryId.GetShopifyMetaObjects,
    JSON.stringify(metaObjectReferences),
    apiUrl,
    token,
  ],
  GetPaginatedCollectionProducts: ({
    merchantId,
    collectionId,
    sortOrder,
    searchText,
  }: {
    merchantId: string;
    collectionId: string;
    sortOrder?: LiteCollectionSortOrder;
    searchText?: string;
  }) => [
    QueryId.GetPaginatedCollectionProducts,
    merchantId,
    collectionId,
    sortOrder,
    searchText,
  ],
  GetShopifyThemes: ({
    merchantId,
    limit,
  }: {
    merchantId: string;
    limit: number;
  }) => [QueryId.GetShopifyThemes, merchantId, limit],
  ShopifyGetImageConfigurationMetafieldsQuery: ({
    collectionId,
  }: {
    collectionId: string;
  }) => [QueryId.ShopifyGetImageConfigurationMetafieldsQuery, collectionId],
  ShopifyGetProductDuplicatesMetafieldsQuery: ({
    collectionId,
  }: {
    collectionId: string;
  }) => [QueryId.ShopifyGetProductDuplicatesMetafieldsQuery, collectionId],
  ShopifyGetColumnSettingsMetafieldsQuery: ({
    merchantId,
  }: {
    merchantId: string;
  }) => [QueryId.ShopifyGetColumnSettingsMetafieldsQuery, merchantId],
  GetConfigValidity: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetConfigValidity,
    merchantId,
  ],
  GetMultiStoreStatus: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetMultiStoreStatus,
    merchantId,
  ],
  [QueryId.ShopifyGetCollections]: (
    variables: GetCollectionsQueryVariables
  ) => [QueryId.ShopifyGetCollections, variables],
  [QueryId.ShopifyGetProducts]: (variables: GetProductsQueryVariables) => [
    QueryId.ShopifyGetProducts,
    variables,
  ],
  [QueryId.ShopifyGetPages]: (variables: GetPagesQueryVariables) => [
    QueryId.ShopifyGetPages,
    variables,
  ],
  [QueryId.ShopifyGetCollectionMetafield]: (
    variables: GetCollectionMetafieldQueryVariables
  ) => [QueryId.ShopifyGetCollectionMetafield, variables],
  [QueryId.ShopifyGetMetaobjects]: (
    variables: GetMetaobjectsQueryVariables
  ) => [QueryId.ShopifyGetMetaobjects, variables],
  [QueryId.GetCollectionProductClicks]: ({
    merchantId,
    collectionId,
    fromDate,
    toDate,
  }: {
    merchantId: string;
    collectionId: string;
    fromDate: string;
    toDate: string;
  }) => [
    QueryId.GetCollectionProductClicks,
    merchantId,
    collectionId,
    fromDate,
    toDate,
  ],
  [QueryId.GetMultiStoreCollectionMappings]: ({
    merchantId,
  }: {
    merchantId: string;
  }) => [QueryId.GetMultiStoreCollectionMappings, merchantId],
  [QueryId.GetMultiStoreCollectionsByShop]: ({
    merchantId,
  }: {
    merchantId: string;
  }) => [QueryId.GetMultiStoreCollectionsByShop, merchantId],
  [QueryId.GetShopifyPublicCollectionImage]: ({
    shopifyBaseDomain,
    collectionHandle,
  }: {
    shopifyBaseDomain?: string;
    collectionHandle?: string;
  }) => [
    QueryId.GetShopifyPublicCollectionImage,
    shopifyBaseDomain,
    collectionHandle,
  ],
  [QueryId.ShopifyGetSubStoreStatus]: ({
    subStoreShopUrl,
  }: {
    subStoreShopUrl?: string;
  }) => [QueryId.ShopifyGetSubStoreStatus, subStoreShopUrl],
  [QueryId.GetShopifyMainTheme]: ({ merchantId }: { merchantId: string }) => [
    QueryId.GetMultiStoreCollectionsByShop,
    merchantId,
  ],
  [QueryId.ShopifyGetImageFiles]: ({
    first,
    after,
    queryString,
  }: {
    first: number;
    after: string | null;
    queryString: string;
  }) => [QueryId.ShopifyGetImageFiles, first, after, queryString],
  [QueryId.GetCollectionTemplates]: ({
    merchantId,
    collectionId,
  }: {
    merchantId: string;
    collectionId: string;
  }) => [QueryId.GetCollectionTemplates, merchantId, collectionId],
  [QueryId.GetActiveCollectionTemplate]: ({
    merchantId,
    collectionId,
  }: {
    merchantId: string;
    collectionId: string;
  }) => [QueryId.GetActiveCollectionTemplate, merchantId, collectionId],
} as const;

type QueryKeyArgs = {
  [K in QueryId]: Parameters<(typeof queryKeyBuilders)[K]>;
};

export function getLiteQueryKey<K extends QueryId>(
  queryId: K,
  ...args: QueryKeyArgs[K]
): QueryKey {
  // @ts-expect-error
  return queryKeyBuilders[queryId](...args);
}
