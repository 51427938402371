import {
  LiteBoostBuryRule,
  LiteCollectionProductDto,
  LiteCollectionSortOrder,
} from "../../../api/types";
import useAuthorizedApi, {
  AuthorizedApi,
} from "../../../helpers/hooks/app/useAuthorizedApi";
import { getData, throwErrorIfAny } from "../../../api/authorizedApi";
import { getLiteQueryKey, QueryId } from "../../queries";
import { useQuery } from "@tanstack/react-query";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";

const getCollectionProductsQuery = async (
  api: AuthorizedApi,
  merchantId: string,
  collectionId: string,
  sortOrder: LiteCollectionSortOrder,
  boostBuryRules: LiteBoostBuryRule["rule"][] | null
): Promise<LiteCollectionProductDto[]> => {
  const response = await api.POST(`/collections/{collection_id}/products`, {
    params: {
      query: {
        merchant_id: merchantId,
        sort_order: sortOrder,
      },
      path: {
        collection_id: collectionId,
      },
    },
    body: boostBuryRules,
  });

  return getData(response);
};

async function getShopifyManualOrdering({
  api,
  collectionId,
  merchantId,
}: {
  api: AuthorizedApi | null;
  collectionId: string;
  merchantId: string;
}) {
  if (!api) {
    return [];
  }

  const res = await api.POST(
    "/collections/{collection_id}/shopify/shopify-ordering",
    {
      params: {
        path: {
          collection_id: collectionId,
        },
        query: {
          merchant_id: merchantId,
        },
      },
    }
  );

  throwErrorIfAny(res);
  return res.data || [];
}

export const useCollectionProducts = (
  collectionId?: string,
  sortOrder?: LiteCollectionSortOrder | "SHOPIFY_SORT",
  boostBuryRules?: LiteBoostBuryRule["rule"][] | null
) => {
  const { merchantId } = useMerchantId();
  const { api } = useAuthorizedApi();

  const query = useQuery({
    queryKey: getLiteQueryKey(QueryId.GetCollectionProducts, {
      merchantId,
      collectionId: collectionId || "",
      sortOrder,
      boostBuryRules: boostBuryRules || [],
    }),
    queryFn: () => {
      if (!api || !sortOrder || !collectionId || !boostBuryRules) {
        return [];
      }

      if (sortOrder === "SHOPIFY_SORT") {
        return getShopifyManualOrdering({
          api,
          collectionId,
          merchantId,
        });
      }

      return getCollectionProductsQuery(
        api,
        merchantId,
        collectionId,
        sortOrder,
        boostBuryRules
      );
    },
    enabled: !!api && !!sortOrder && !!collectionId && !!boostBuryRules,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return {
    products: query.data ?? null,
    isLoading: query.isLoading,
  };
};
