import { DynamicStatusConfig, LiteBadgeType } from "../../../api/types";
import { Theme } from "@emotion/react";
import { DnDBoostBuryRule } from "./Rule";

export type BoostBuryLevel = "collection" | "global";

export type BoostBuryRules =
  | {
      boost: DnDBoostBuryRule[];
      bury: DnDBoostBuryRule[];
    }
  | undefined;

export function dynamicStatusMeta(
  dynamicStatusConfig?: DynamicStatusConfig
): Record<LiteBadgeType, { title: string; description: string }> {
  return {
    on_sale: {
      title: "On Sale",
      description: "Product has a discount",
    },
    out_of_stock: {
      title: "Out of stock",
      description: "Product has no variants left in stock",
    },
    new_in: {
      title: "New in",
      description: `Product added in the last ${dynamicStatusConfig?.new_in_days ?? "?"} days`,
    },
    bestseller: {
      title: "Bestseller",
      description: `Top ${dynamicStatusConfig?.bestseller_n_products ?? "?"} products sold in the last 30 days`,
    },
    slow_mover: {
      title: "Slow mover",
      description: `Bottom ${dynamicStatusConfig?.slowmover_n_products ?? "?"} products in terms of sell-through rate last 30 days`,
    },
    few_variants_available: {
      title: "Low on Stock",
      description: `Product has less than ${dynamicStatusConfig?.low_in_stock_percentage ?? "?"}% of its variants in stock`,
    },
    trending: {
      title: "Trending",
      description: `Top ${dynamicStatusConfig?.trending_n_products ?? "?"} products sold in the last 7 days`,
    },
  };
}

export function badgeColor(theme: Theme, badgeType: LiteBadgeType) {
  const { highlighted, faded, information, feature, away, warning, stable } =
    theme.colors.state;
  switch (badgeType) {
    case "on_sale":
      return [highlighted.dark, highlighted.light];
    case "out_of_stock":
      return [faded.dark, faded.light];
    case "new_in":
      return [information.dark, information.light];
    case "bestseller":
      return [feature.dark, feature.light];
    case "slow_mover":
      return [away.dark, away.light];
    case "few_variants_available":
      return [warning.dark, warning.light];
    case "trending":
      return [stable.dark, stable.light];
  }
}
